.calendar header {
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
}

.calendar header button,
.calendar header select {
    margin: 0 4px;
}

.calendar table {
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: 0;
    margin: auto;
}

.calendar th {
    vertical-align: middle;
    text-align: center;
    height: 36px;
}

.calendar .day {
    padding: 4px 8px;
    vertical-align: middle;
    text-align: center;
    border: 2px solid transparent;
}

.calendar .day:hover {
    @apply rounded-full cursor-pointer text-blue-500

}

/* Выделение дня мышкой */
.calendar .day.selected {
    border: 2px solid dodgerblue;
    @apply rounded-full
}

/* Выделение сегодняшнего дня */
.calendar .day.today {
    background-color: dodgerblue;
    color: white;
    @apply rounded-full
}

.calendar .day.today:hover {
    background-color: #0081ff;
}

.calendar .day.today.selected {
    border: 2px solid lightgray;
}