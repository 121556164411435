/* айфоновский чекбокс */
.checkbox.style-e {
    display: inline-block;
    position: relative;
    padding-left: 50px;
    padding-top: 2px;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0); 
    -webkit-tap-highlight-color: transparent;

    -moz-user-select: none; 
    -webkit-user-select: none; 
    -ms-user-select: none;
    -o-user-select:none;
    -khtml-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;

    /* pointer-events: none; */

    @apply select-none
  }
  .checkbox.style-e input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0px;
    width: 0px;
  }
  .checkbox.style-e input:checked ~ .checkbox__checkmark {
    background-color: var(--button-blue_bg_color);
    /* @apply bg-blue-500; */
  }
  .checkbox.style-e input:checked ~ .checkbox__checkmark:after {
    left: 21px;
  }
  .checkbox.style-e:hover input ~ .checkbox__checkmark {
    background-color: var(--button-blue_bg_color);
    
  }
  .checkbox.style-e:hover input:checked ~ .checkbox__checkmark {
    background-color: var(--second-blue_element);
    /* #f7cb15 orange */
  }
  .checkbox.style-e .checkbox__checkmark {
    position: absolute;
    top: 1px;
    left: 0;
    height: 22px;
    width: 40px;
    /* transition: background-color 0.25s ease; */
    border-radius: 11px;
    background-color: var(--bright-gray-button-style);
    box-shadow: inset 0px 0px 4px 1px rgba(0, 0, 0, 0.3);
    

  }
  .checkbox.style-e .checkbox__checkmark:after {
    content: "";
    position: absolute;
    left: 3px;
    top: 3px;
    width: 16px;
    height: 16px;
    /* display: block; */
    background-color: #fff;
    border-radius: 50%;
    transition: left 0.25s ease;
  }
  /* .checkbox.style-e .checkbox__body {
    color: #333;
    line-height: 1.4;
    font-size: 16px;
    transition: color 0.25s ease;
  } */