/* ============================================= */
/* ============================================= */

@keyframes dots_color_animation {
  0%   {  background-color: rgb(233, 233, 233);}
  30%   {  background-color: rgb(230, 230, 230);}
  33%   {  background-color: rgb(85, 255, 0);}
  36%  {  background-color: rgb(0, 255, 251);}
  39%   {  background-color: rgb(208, 0, 255);}
  42% {  background-color: rgb(255, 0, 0); }
  45%   {  background-color: rgb(183, 183, 183);}
  100%   {  background-color: rgb(236, 236, 236);}
}

/* ============================================= */
/* ============================================= */


.matrix_visual_animation_horisontal_stick {
  width: 50px;
  animation: matrix_visual_animation_horisontal_stick 10s ease-in-out infinite;
}

@keyframes matrix_visual_animation_horisontal_stick {
  0%   {  left: 250px ;  background-color: rgb(255, 0, 0);}
  25%   {  background-color: rgb(251, 255, 0);}
  50%  {  left: 0px; background-color: rgb(176, 122, 220);}
  75%   {  background-color: rgb(251, 255, 0);}
  100% {  left: 250px;  background-color: rgb(255, 0, 0); }
}

/* ============================================= */
/* ============================================= */


.matrix_visual_animation_vertical_stick {
  height: 50px;
  animation: matrix_visual_animation_vertical_stick 10s ease-in-out infinite;
}

@keyframes matrix_visual_animation_vertical_stick {
  0%   {  top: 250px ;  background-color: rgb(255, 0, 0);}
  25%   {  background-color: rgb(251, 255, 0);}
  50%  {  top: 0px; background-color: rgb(176, 122, 220);}
  75%   {  background-color: rgb(251, 255, 0);}
  100% {  top: 250px;  background-color: rgb(255, 0, 0); }
}

/* ============================================= */
/* ============================================= */

.button_shadow_z {
  transform: rotateY(30deg);
}

/* ============================================= */
/* ============================================= */


.animation_go_from_right_1 {
  animation: animation_go_from_right_1 10s ease-in-out infinite;
}

@keyframes animation_go_from_right_1 {
  0%   {  padding-left: 100% ;  }
  25%  {  padding-left: 50%; }
  50%  {  padding-left: 0%; }
  75%  {  padding-left: 50%; }
  100% {  padding-left: 100%;  }
}

/* ============================================= */
/* ============================================= */


.animation_bounce_random_figure_3 {
  animation: animation_bounce_random_figure_3 10s ease-in-out infinite;
}

@keyframes animation_bounce_random_figure_3 {
  0%   {  border-radius: 0% 100% 0% 100% / 81% 21% 79% 19%  ;  }
  25%  {  border-radius: 12% 88% 22% 78% / 81% 0% 100% 19%     ; }
  50%  {  border-radius: 0% 100% 0% 100% / 100% 0% 100% 0%   ; }
  75%  {  border-radius: 0% 100% 0% 100% / 100% 17% 83% 0%    ; }
  100% {  border-radius: 0% 100% 0% 100% / 81% 21% 79% 19%  ;  }
}



/* ============================================= */
/* ============================================= */


.animation_bounce_random_figure_2 {
  animation: animation_bounce_random_figure_2 10s ease-in-out infinite;
}

@keyframes animation_bounce_random_figure_2 {
  0%   {  border-radius: 0% 100% 0% 100% / 100% 0% 100% 0% ;  }
  25%  {  border-radius: 0% 100% 0% 100% / 100% 36% 64% 0%    ; }
  50%  {  border-radius: 33% 67% 0% 100% / 81% 0% 100% 19%   ; }
  75%  {  border-radius: 0% 100% 45% 55% / 81% 0% 100% 19%   ; }
  100% {  border-radius: 0% 100% 0% 100% / 100% 0% 100% 0% ;  }
}


/* ============================================= */
/* ============================================= */


.animation_bounce_random_figure_1 {
  animation: animation_bounce_random_figure_1 10s ease-in-out infinite;
}

@keyframes animation_bounce_random_figure_1 {
  0%   {  border-radius: 97% 3% 97% 3% / 3% 96% 4% 97% ;  }
  25%  {  border-radius: 0% 100% 0% 100% / 100% 0% 100% 0%   ; }
  50%  {  border-radius: 11% 89% 17% 83% / 86% 79% 21% 14%  ; }
  75%  {  border-radius: 100% 0% 80% 20% / 100% 79% 21% 0%  ; }
  100% {  border-radius: 97% 3% 97% 3% / 3% 96% 4% 97%;  }
}


/* ============================================= */
/* ============================================= */

.animation_logo_rotation {
  animation: animation_logo_rotation 10s ease-in-out infinite;

}

@keyframes animation_logo_rotation {
  0%   { left: 0%; transform: rotate(30deg); top: 0px; }
  25%  { left: 10%; transform: rotate(180deg); width: 100px; height: 100px; top: -10px; }
  50%  { left: 10%; transform: rotate(0deg); width: 100px; height: 100px; top: -10px; }
  75%  { left: 10%; transform: rotate(180deg); width: 100px; height: 100px; top: -10px; }
  100% { left: 0%; transform: rotate(30deg); top: 0px; }
}

/* ============================================= */
/* ============================================= */


.animation_bounce_from_top_commercial_x_4 {
  animation: animation_bounce_from_top_commercial_x_4 10s ease-in-out infinite;
}

@keyframes animation_bounce_from_top_commercial_x_4 {
  0%   {  transform: rotate(0deg);  border-radius: 100% 0% 100% 0% / 100% 0% 100% 0%  ;  }
  /* 25%  {  transform: rotate(90deg);  border-radius: 0% 100% 0% 100% / 0% 100% 0% 100%   ; } */
  50%  {  transform: rotate(180deg);  border-radius: 0% 100% 0% 100% / 0% 100% 0% 100%; }
  /* 75%  {  transform: rotate(-90deg);  border-radius: 0% 100% 0% 100% / 0% 100% 0% 100% ; } */
  100% {  transform: rotate(0deg); border-radius: 100% 0% 100% 0% / 100% 0% 100% 0% ;  }
}
/* ============================================= */
/* ============================================= */


.animation_bounce_from_top_commercial_x_3 {
  animation: animation_bounce_from_top_commercial_x_3 10s ease-in-out infinite;
}

@keyframes animation_bounce_from_top_commercial_x_3 {
  0%   {  transform: rotate(0deg);  border-radius: 0% 100% 0% 100% / 0% 100% 0% 100% ;  }
  /* 25%  {  transform: rotate(90deg);  border-radius: 0% 100% 0% 100% / 0% 100% 0% 100%   ; } */
  50%  {  transform: rotate(180deg);  border-radius: 100% 0% 100% 0% / 100% 0% 100% 0%   ; }
  /* 75%  {  transform: rotate(-90deg);  border-radius: 0% 100% 0% 100% / 0% 100% 0% 100% ; } */
  100% {  transform: rotate(0deg); border-radius: 0% 100% 0% 100% / 0% 100% 0% 100%;  }
}

/* ============================================= */
/* ============================================= */


.animation_bounce_from_top_commercial_2 {
  animation: animation_bounce_from_top_commercial_2 10s ease-in-out infinite;
}

@keyframes animation_bounce_from_top_commercial_2 {
  0%   {  transform: rotate(0deg);  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;  }
  25%  {  transform: rotate(90deg);  border-radius: 100% 0% 0% 100% / 56% 100% 0% 44%  ; }
  50%  {  transform: rotate(180deg);  border-radius: 16% 84% 87% 13% / 100% 0% 100% 0% ; }
  75%  {  transform: rotate(-90deg);  border-radius: 0% 100% 0% 100% / 0% 100% 0% 100% ; }
  100% {  transform: rotate(0deg); border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;  }
}

/* ============================================= */
/* ============================================= */


.animation_bounce_from_top_commercial {
  animation: animation_bounce_from_top_commercial 10s ease-in-out infinite;
}

@keyframes animation_bounce_from_top_commercial {
  0%   { top: 0%; transform: rotate(0deg); }
  25%  { top: 10%; transform: rotate(10deg); }
  50%  { top: -1%; transform: rotate(0deg); }
  75%  { top: 1%; transform: rotate(-10deg); }
  100% { top: 0%; transform: rotate(0deg); }
}

/* ============================================= */
/* ============================================= */


.animation_bounce_from_left_commercial {
  animation: animation_bounce_from_left_commercial 10s ease-in-out infinite;
}

@keyframes animation_bounce_from_left_commercial {
  0%   { left: var(--variable_for_animation_1); transform: rotate(0deg); }
  25%  { left: 1%; transform: rotate(10deg); }
  50%  { left: -1%; transform: rotate(0deg); }
  75%  { left: var(--variable_for_animation_2); transform: rotate(-10deg); }
  100% { left: var(--variable_for_animation_1); transform: rotate(0deg); }
}

/* ============================================= */
/* ============================================= */

/* Анимация Описания Матрицы */
.animation_bounce_from_left {
    animation: animation_bounce_from_left 10s ease-in-out infinite;
}

@keyframes animation_bounce_from_left {
    0%   { left: var(--variable_for_animation_1); transform: rotate(0deg); }
    25%  { left: var(--variable_for_animation_2); transform: rotate(10deg); }
    50%  { left: var(--variable_for_animation_3); transform: rotate(0deg); }
    75%  { left: var(--variable_for_animation_2); transform: rotate(-10deg); }
    100% { left: var(--variable_for_animation_1); transform: rotate(0deg); }
  }

  /* @-webkit-keyframes animation_bounce_from_left {
    0%   { -webkit-left: var(--variable_for_animation_1); -webkit-transform: rotate(0deg); }
    25%  { -webkit-left: var(--variable_for_animation_2); -webkit-transform: rotate(10deg); }
    50%  { -webkit-left: var(--variable_for_animation_3); -webkit-transform: rotate(0deg); }
    75%  { -webkit-left: var(--variable_for_animation_2); -webkit-transform: rotate(-10deg); }
    100% { -webkit-left: var(--variable_for_animation_1); -webkit-transform: rotate(0deg); }
  } */

  .animation_bounce_from_right {
    animation: animation_bounce_from_right 10s ease-in-out infinite;
}

@keyframes animation_bounce_from_right{
    0%   { right: var(--variable_for_animation_1); transform: rotate(0deg); }
    25%  { right: var(--variable_for_animation_2); transform: rotate(10deg); }
    50%  { right: var(--variable_for_animation_3); transform: rotate(0deg); }
    75%  { right: var(--variable_for_animation_2); transform: rotate(-10deg); }
    100% { right: var(--variable_for_animation_1); transform: rotate(0deg); }
  }

  /* @-webkit-keyframes animation_bounce_from_right {
    0%   { -webkit-right: var(--variable_for_animation_1); -webkit-transform: rotate(0deg); }
    25%  { -webkit-right: var(--variable_for_animation_2); -webkit-transform: rotate(10deg); }
    50%  { -webkit-right: var(--variable_for_animation_3); -webkit-transform: rotate(0deg); }
    75%  { -webkit-right: var(--variable_for_animation_2); -webkit-transform: rotate(-10deg); }
    100% { -webkit-right: var(--variable_for_animation_1); -webkit-transform: rotate(0deg); }
  } */

/* ============================================= */
/* ============================================= */

.animation_bounce_ball {
  animation: animation_bounce_ball 10s ease-in-out infinite;
}

@keyframes animation_bounce_ball {
  0%   { left: var(--variable_for_animation_3); transform: rotate(0deg); border-radius: 9999px; }
  25%  { left: var(--variable_for_animation_1); transform: rotate(10deg); border-radius: 100px; }
  50%  { left: 30%; transform: rotate(0deg); transform: rotate(180deg);  border-radius: 10px; }
  75%  { left: var(--variable_for_animation_1); transform: rotate(360deg);  border-radius: 100px; }
  100% { left: var(--variable_for_animation_3); transform: rotate(0deg);  border-radius: 9999px; }
}


/* ============================================================= */
/* ============================================================= */

.dance_button {
  animation-name: dance_button;
  animation-duration: 4s;
  animation-iteration-count: infinite;
}

@keyframes dance_button {
  0%   {transform: rotate(0deg);}
  25%  {transform: rotate(.2deg);}
  50%  {transform: rotate(0deg);}
  75%  {transform: rotate(-.2deg);}
  100% {transform: rotate(0deg);}
}

@-webkit-keyframes dance_button {
  0%   {-webkit-transform: rotate(0deg);}
  25%  {-webkit-transform: rotate(.2deg);}
  50%  {-webkit-transform: rotate(0deg);}
  75%  {-webkit-transform: rotate(-.2deg);}
  100% {-webkit-transform: rotate(0deg);}
}
/* ============================================================= */
/* ============================================================= */

.dance_moon {
  animation-name: dance_moon;
  animation-duration: 10s;
  animation-iteration-count: infinite;
}

@keyframes dance_moon {
  0%   {transform: rotate(0deg);}
  25%  {transform: rotate(.2deg);}
  50%  {transform: rotate(0deg);}
  75%  {transform: rotate(-.2deg);}
  100% {transform: rotate(0deg);}
}

@-webkit-keyframes dance_moon {
  0%   {-webkit-transform: rotate(0deg);}
  25%  {-webkit-transform: rotate(.2deg);}
  50%  {-webkit-transform: rotate(0deg);}
  75%  {-webkit-transform: rotate(-.2deg);}
  100% {-webkit-transform: rotate(0deg);}
}

/* ============================================================= */
/* ============================================================= */
.earth_moon_center_rotate_reversed {
  animation: earth_moon_center_rotate 50s linear infinite;
}

.earth_moon_center_rotate {
  /* animation-name: earth_moon_center_rotate;
  animation-duration: 10s;
  animation-iteration-count: infinite; */
  animation: earth_moon_center_rotate 50s linear infinite;
  animation-direction: reverse;

}

@keyframes earth_moon_center_rotate {
  0%   {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}

@-webkit-keyframes earth_moon_center_rotate {
  0%   {-webkit-transform: rotate(0deg);}
  100% {-webkit-transform: rotate(360deg);}
}

/* ============================================================= */
/* ============================================================= */

/* .dance_button_near_matrix {
  animation-name: dance_button_near_matrix;
  animation-duration: 20s;
  animation-iteration-count: infinite;
} */

@keyframes dance_button_near_matrix {
  0%   {transform: rotate(0deg);}
  25%  {transform: rotate(10deg);}
  50%  {transform: rotate(0deg);}
  75%  {transform: rotate(-6deg);}
  100% {transform: rotate(0deg);}
}

@-webkit-keyframes dance_button_near_matrix {
  0%   {-webkit-transform: rotate(0deg);}
  25%  {-webkit-transform: rotate(10deg);}
  50%  {-webkit-transform: rotate(0deg);}
  75%  {-webkit-transform: rotate(-60deg);}
  100% {-webkit-transform: rotate(0deg);}
}

/* ============================================================= */
/* ============================================================= */

@keyframes dontActiveButton {
	0%,
	100% {
		transform: translateX(0%);
		transform-origin: 50% 50%;
	}

	15% {
		transform: translateX(-30px) rotate(-6deg);
	}

	30% {
		transform: translateX(15px) rotate(6deg);
	}

	45% {
		transform: translateX(-15px) rotate(-3.6deg);
	}

	60% {
		transform: translateX(9px) rotate(2.4deg);
	}

	75% {
		transform: translateX(-6px) rotate(-1.2deg);
	}
}

/* ============================================================= */
/* ============================================================= */

.animation_for_cards:hover {
  animation: cards_animation_1 2s ease 0s 1 normal forwards;
}

@keyframes cards_animation_1 {
	0% {
		transform: scale3d(1, 1, 1);
	}

	30% {
		transform: scale3d(1.25, 0.75, 1);
	}

	40% {
		transform: scale3d(0.75, 1.25, 1);
	}

	50% {
		transform: scale3d(1.15, 0.85, 1);
	}

	65% {
		transform: scale3d(0.95, 1.05, 1);
	}

	75% {
		transform: scale3d(1.05, 0.95, 1);
	}

	100% {
		transform: scale3d(1, 1, 1);
	}
}



.top_picture_gradient {
	background: linear-gradient(90deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 400% 400%;
	animation: top_picture_gradient_animation 10s ease infinite;
	transform: translate3d(0, 0, 0);
}
 
@keyframes top_picture_gradient_animation {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}


/* АНИМАЦИЯ МАТРИЧНЫХ ТОЧЕК */

.dots_color_style:nth-child(1)  {
    animation: dots_color_animation 5s ease-in-out infinite;
}

.dots_color_style:nth-child(2)  {
    animation: dots_color_animation 5s ease-in-out infinite;
    animation-delay: 50ms;
}

.dots_color_style:nth-child(3)  {
    animation: dots_color_animation 5s ease-in-out infinite;
    animation-delay: 100ms;
}

.dots_color_style:nth-child(4)  {
    animation: dots_color_animation 5s ease-in-out infinite;
    animation-delay: 150ms;
}

.dots_color_style:nth-child(5)  {
    animation: dots_color_animation 5s ease-in-out infinite;
    animation-delay: 200ms;
}

.dots_color_style:nth-child(6)  {
    animation: dots_color_animation 5s ease-in-out infinite;
    animation-delay: 250ms;
}

.dots_color_style:nth-child(7)  {
    animation: dots_color_animation 5s ease-in-out infinite;
    animation-delay: 300ms;
}

.dots_color_style:nth-child(8)  {
    animation: dots_color_animation 5s ease-in-out infinite;
    animation-delay: 350ms;
}

.dots_color_style:nth-child(9)  {
    animation: dots_color_animation 5s ease-in-out infinite;
    animation-delay: 400ms;
}

.dots_color_style:nth-child(10)  {
    animation: dots_color_animation 5s ease-in-out infinite;
    animation-delay: 450ms;
}

.dots_color_style:nth-child(11)  {
    animation: dots_color_animation 5s ease-in-out infinite;
    animation-delay: 500ms;
}

.dots_color_style:nth-child(12)  {
    animation: dots_color_animation 5s ease-in-out infinite;
    animation-delay: 550ms;
}

.dots_color_style:nth-child(13)  {
    animation: dots_color_animation 5s ease-in-out infinite;
    animation-delay: 600ms;
}

.dots_color_style:nth-child(14)  {
    animation: dots_color_animation 5s ease-in-out infinite;
    animation-delay: 650ms;
}

.dots_color_style:nth-child(15)  {
    animation: dots_color_animation 5s ease-in-out infinite;
    animation-delay: 700ms;
}

.dots_color_style:nth-child(16)  {
    animation: dots_color_animation 5s ease-in-out infinite;
    animation-delay: 750ms;
}

.dots_color_style:nth-child(17)  {
    animation: dots_color_animation 5s ease-in-out infinite;
    animation-delay: 800ms;
}

.dots_color_style:nth-child(18)  {
    animation: dots_color_animation 5s ease-in-out infinite;
    animation-delay: 850ms;
}

.dots_color_style:nth-child(19)  {
    animation: dots_color_animation 5s ease-in-out infinite;
    animation-delay: 900ms;
}

.dots_color_style:nth-child(20)  {
    animation: dots_color_animation 5s ease-in-out infinite;
    animation-delay: 950ms;
}

.dots_color_style:nth-child(21)  {
    animation: dots_color_animation 5s ease-in-out infinite;
    animation-delay: 1000ms;
}

.dots_color_style:nth-child(22)  {
    animation: dots_color_animation 5s ease-in-out infinite;
    animation-delay: 1050ms;
}

.dots_color_style:nth-child(23)  {
    animation: dots_color_animation 5s ease-in-out infinite;
    animation-delay: 1100ms;
}

.dots_color_style:nth-child(24)  {
    animation: dots_color_animation 5s ease-in-out infinite;
    animation-delay: 1150ms;
}

.dots_color_style:nth-child(25)  {
    animation: dots_color_animation 5s ease-in-out infinite;
    animation-delay: 1200ms;
}

.dots_color_style:nth-child(26)  {
    animation: dots_color_animation 5s ease-in-out infinite;
    animation-delay: 1250ms;
}

.dots_color_style:nth-child(27)  {
    animation: dots_color_animation 5s ease-in-out infinite;
    animation-delay: 1300ms;
}

.dots_color_style:nth-child(28)  {
    animation: dots_color_animation 5s ease-in-out infinite;
    animation-delay: 1350ms;
}

.dots_color_style:nth-child(29)  {
    animation: dots_color_animation 5s ease-in-out infinite;
    animation-delay: 1400ms;
}

.dots_color_style:nth-child(30)  {
    animation: dots_color_animation 5s ease-in-out infinite;
    animation-delay: 1450ms;
}


.dots_color_style:nth-child(31)  {
    animation: dots_color_animation 5s ease-in-out infinite;
    animation-delay: 1500ms;
}

.dots_color_style:nth-child(32)  {
    animation: dots_color_animation 5s ease-in-out infinite;
    animation-delay: 1550ms;
}

.dots_color_style:nth-child(33)  {
    animation: dots_color_animation 5s ease-in-out infinite;
    animation-delay: 1600ms;
}

.dots_color_style:nth-child(34)  {
    animation: dots_color_animation 5s ease-in-out infinite;
    animation-delay: 1650ms;
}

.dots_color_style:nth-child(35)  {
    animation: dots_color_animation 5s ease-in-out infinite;
    animation-delay: 1700ms;
}

.dots_color_style:nth-child(36)  {
    animation: dots_color_animation 5s ease-in-out infinite;
    animation-delay: 1750ms;
}

.dots_color_style:nth-child(37)  {
    animation: dots_color_animation 5s ease-in-out infinite;
    animation-delay: 1800ms;
}

.dots_color_style:nth-child(38)  {
    animation: dots_color_animation 5s ease-in-out infinite;
    animation-delay: 1850ms;
}

.dots_color_style:nth-child(39)  {
    animation: dots_color_animation 5s ease-in-out infinite;
    animation-delay: 1900ms;
}

.dots_color_style:nth-child(40)  {
    animation: dots_color_animation 5s ease-in-out infinite;
    animation-delay: 1950ms;
}

.dots_color_style:nth-child(41)  {
    animation: dots_color_animation 5s ease-in-out infinite;
    animation-delay: 2000ms;
}

.dots_color_style:nth-child(42)  {
    animation: dots_color_animation 5s ease-in-out infinite;
    animation-delay: 2050ms;
}

.dots_color_style:nth-child(43)  {
    animation: dots_color_animation 5s ease-in-out infinite;
    animation-delay: 2100ms;
}

.dots_color_style:nth-child(44)  {
    animation: dots_color_animation 5s ease-in-out infinite;
    animation-delay: 2150ms;
}

.dots_color_style:nth-child(45)  {
    animation: dots_color_animation 5s ease-in-out infinite;
    animation-delay: 2200ms;
}

.dots_color_style:nth-child(46)  {
    animation: dots_color_animation 5s ease-in-out infinite;
    animation-delay: 2250ms;
}
.dots_color_style:nth-child(47)  {
    animation: dots_color_animation 5s ease-in-out infinite;
    animation-delay: 2300ms;
}

.dots_color_style:nth-child(48)  {
    animation: dots_color_animation 5s ease-in-out infinite;
    animation-delay: 2350ms;
}

.dots_color_style:nth-child(49)  {
    animation: dots_color_animation 5s ease-in-out infinite;
    animation-delay: 2400ms;
}

.dots_color_style:nth-child(50)  {
    animation: dots_color_animation 5s ease-in-out infinite;
    animation-delay: 2450ms;
}

.dots_color_style:nth-child(51)  {
    animation: dots_color_animation 5s ease-in-out infinite;
    animation-delay: 2500ms;
}

.dots_color_style:nth-child(52)  {
    animation: dots_color_animation 5s ease-in-out 10s infinite;
    animation-delay: 2550ms;
}

.dots_color_style:nth-child(53)  {
    animation: dots_color_animation 5s ease-in-out 10s infinite;
    animation-delay: 2600ms;
}

.dots_color_style:nth-child(54)  {
    animation: dots_color_animation 5s ease-in-out 10s infinite;
    animation-delay: 2650ms;
}

.dots_color_style:nth-child(55)  {
    animation: dots_color_animation 5s ease-in-out 0.5s infinite;
    animation-delay: 2700ms;
}

.dots_color_style:nth-child(56)  {
    animation: dots_color_animation 5s ease-in-out 0.5s infinite;
    animation-delay: 2750ms;
}

