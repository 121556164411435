textarea {
    background: var(--bg-inputs);
}

input {
    background: var(--bg-inputs);
    @apply shadow rounded-3xl w-full h-10 py-2 px-3 leading-tight focus:outline-blue-300 placeholder:text-sm 
}

.input_style {
    background: var(--bg-inputs);
    @apply appearance-none shadow rounded-3xl w-full h-10 py-2 px-3  leading-tight focus:outline-blue-300 placeholder:text-sm 
}

.textarea_div_bio {
    overflow-wrap: break-word;  /* не поддерживает IE, Firefox; является копией word-wrap */ 
    word-wrap: normal;
    word-break: normal;  /* не поддерживает Opera12.14, значение keep-all не поддерживается IE, Chrome */ 
    line-break: auto;  /* нет поддержки для русского языка */ 
    -webkit-hyphens: auto; -ms-hyphens: auto; hyphens: auto;  /* значение auto не поддерживается Chrome */ 
}

.input_style_status {
    background: var(--bg-inputs);
    @apply rounded-full w-full h-8 py-1 px-3 leading-tight border-[1px] border-blue-300 focus:outline-blue-300 placeholder:text-sm 
}

input[type="date"]{
    @apply flex
}

select {
    background: var(--bg-inputs);
    @apply rounded-3xl p-1
}

/* input[type="date"]::-webkit-calendar-picker-indicator{
    @apply flex items-center justify-center bg-blue-400 cursor-pointer rounded-full p-10 hover:bg-blue-500
} */


/* input[type="file"] {
    @apply rounded w-full
} */

/* input[type="file"]::-webkit-file-upload-button {
    @apply bg-blue-300 rounded-md m-1 hidden
}  */


/* input[type="file"]::before {
    content: "Файл не выбsран";
  } */

/* PASSWORD \/\/\/ */
input[type="password"]::-ms-reveal {
    border: 1px solid transparent;
    border-radius: 50%;
    box-shadow: 0 0 3px currentColor;
}
/* PASSWORD /\/\/\ */

.input_transform {
    transform: scale(50%);
}

/* CHECKBOX \/\/\/ */
input[type=checkbox] {
    transition: background 300ms;
    box-shadow: inset 0 0 0 1px #CCD3D8;
    @apply w-[16px] h-[23px] bg-white  appearance-none cursor-pointer bg-contain
  }
  
  /* Checked */
input[type=checkbox]:checked {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
    @apply bg-blue-500
}

  
  /* Disabled */
  
  /* input[type=checkbox]:disabled {
    background-color: #CCD3D8;
    opacity: 0.84;
    cursor: not-allowed;
  } */
  
  
  /* IE */
  
  /* input[type=checkbox]::-ms-check {
    content: "";
    color: transparent;
    display: block;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    border: 0;
    background-color: transparent;
    background-size: contain;
    box-shadow: inset 0 0 0 1px #CCD3D8;
  } */
  
  /* input[type=checkbox]:checked::-ms-check {
    box-shadow: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
  } */
/* CHECKBOX  /\/\/\ */

