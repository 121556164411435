

/* ============================================================= */
/* ============================================================= */

.button_ava_posts {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0); 
  -webkit-tap-highlight-color: transparent;
  transform-style: preserve-3d;

  /* animation-name: dance_button_near_matrix;
  animation-duration: 20s;
  animation-iteration-count: infinite; */

  @apply cursor-pointer duration-500 rounded-full
}

.button_ava_posts:hover {
  transform: rotate(-30deg);
  @apply scale-125  z-[1] rotate-[20deg]
   }

.button_ava_posts:active {
    transition: .1s linear;

    box-shadow:  4px 4px 6px 0 rgba(255,255,255,.3),
    -4px -4px 6px 0 rgba(116, 125, 136, .2), 
    inset -4px -4px 6px 0 rgba(255,255,255,.2),
    inset 4px 4px 6px 0 rgba(0, 0, 0, .2);

    @apply border-none scale-100
}

/* ============================================================= */
/* ============================================================= */

.button_near_matrix {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0); 
  -webkit-tap-highlight-color: transparent;
  transform-style: preserve-3d;

  /* animation-name: dance_button_near_matrix;
  animation-duration: 20s;
  animation-iteration-count: infinite; */

  @apply flex items-center justify-center border-b-2 border-l-[0.5px] cursor-pointer duration-500 shadow rounded-full
}

.button_near_matrix:hover {
  transform: rotate(-30deg);
  @apply bg-blue-400 border-b-0 border-t-4 scale-125 text-white border-gray-300 z-[1] rotate-[20deg]
   }

.button_near_matrix:active {
    transition: .1s linear;

    box-shadow:  4px 4px 6px 0 rgba(255,255,255,.3),
    -4px -4px 6px 0 rgba(116, 125, 136, .2), 
    inset -4px -4px 6px 0 rgba(255,255,255,.2),
    inset 4px 4px 6px 0 rgba(0, 0, 0, .2);

    @apply border-none scale-100
}


/* ============================================================= */
/* ============================================================= */

.matrix_right_info_button {
  @apply flex items-center justify-center border border-blue-200 w-10 h-full rounded-full p-2 cursor-pointer hover:bg-blue-300
}

/* ============================================================= */
/* ============================================================= */
.button_from_nowhere_before {
  perspective: 221px;  
  /* @apply rounded-full */
}

/* .after_style {
  transform-style: preserve-3d;
  transform: rotateX(220deg) rotateY(0deg);
} */

.button_from_nowhere {
  background-color: var(--button-blue_bg_color);
  color: var(--text-inverted);

  -webkit-tap-highlight-color: rgba(255, 255, 255, 0); 
  -webkit-tap-highlight-color: transparent;

  -webkit-transition: all .6s ease-in-out;
  -moz-transition: all .6s ease-in-out;
  -ms-transition: all .6s ease-in-out;
  -o-transition: all .6s ease-in-out;
  transition: all .6s ease-in-out;

  transform-style: preserve-3d;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @apply h-10 select-none cursor-pointer py-2 px-4 rounded-full border-b-2 border-t-0 border-blue-200
}

.button_from_nowhere:hover {
  transform: rotateX(20deg) rotateY(0deg);

  transition: .5s linear;
  background-position: -200% 0, 0 0, 0 0, 0 100%, 0 4px, 0 calc(100% - 4px);
  @apply  bg-green-200 border border-b-4  border-t-0  border-blue-200  shadow-md
}

.button_from_nowhere:active {
  transition: .5s linear;

  box-shadow:  4px 4px 6px 0 rgba(255,255,255,.3),
  -4px -4px 6px 0 rgba(116, 125, 136, .2), 
  inset -4px -4px 6px 0 rgba(255,255,255,.2),
  inset 4px 4px 6px 0 rgba(0, 0, 0, .2);

  @apply border-none 
}


/* ============================================================= */
/* ============================================================= */

.button_navigation {
    @apply ease-in-out duration-500
}

.button_navigation:hover {
    @apply border-b-0 z-[1]
   }

.button_navigation:active {
    transition: .1s linear;

    box-shadow:  1px 1px 2px 0 rgba(255,255,255,.3),
    -1px -1px 2px 0 rgba(116, 125, 136, .2), 
    inset -1px -1px 2px 0 rgba(255,255,255,.2),
    inset 1px 1px 2px 0 rgba(0, 0, 0, .2);

    @apply border-none scale-100
}

/* ============================================================= */
/* ============================================================= */

.button_matrix_little {
    @apply   border-b-2 cursor-pointer duration-500 shadow 
   }
   
   .button_matrix_little:hover {
       @apply bg-blue-400 border-b-0 border-t-[3px] scale-110 text-white border-gray-300 z-[1]
      }
   
   .button_matrix_little:active {
       transition: .1s linear;
   
       box-shadow:  4px 4px 6px 0 rgba(255,255,255,.3),
       -4px -4px 6px 0 rgba(116, 125, 136, .2), 
       inset -4px -4px 6px 0 rgba(255,255,255,.2),
       inset 4px 4px 6px 0 rgba(0, 0, 0, .2);
   
       @apply border-none scale-100
   }

/* ============================================================= */
/* ============================================================= */

.button_matrix {
  transform-style: preserve-3d;
  @apply   border-b-2 cursor-pointer duration-500 shadow
}

.button_matrix:hover {
    @apply bg-blue-400 border-b-0 border-t-4 scale-110 text-white border-gray-300 z-[1]
   }

.button_matrix:active {
    transition: .1s linear;

    box-shadow:  4px 4px 6px 0 rgba(255,255,255,.3),
    -4px -4px 6px 0 rgba(116, 125, 136, .2), 
    inset -4px -4px 6px 0 rgba(255,255,255,.2),
    inset 4px 4px 6px 0 rgba(0, 0, 0, .2);

    @apply border-none scale-100
}

/* ============================================================= */
/* ============================================================= */

/* КНОПКА серая для меню */
.button_gray_square {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0); 
    -webkit-tap-highlight-color: transparent;

    -webkit-transition: all .1s ease-in-out;
    -moz-transition: all .1s ease-in-out;
    -ms-transition: all .1s ease-in-out;
    -o-transition: all .1s ease-in-out;
    transition: all .1s ease-in-out;

    animation-name: dance_button;
    animation-duration: 4s;
    animation-iteration-count: infinite;

    background-color: var(--dark-gray-button-style);
    border-color: var(--bright-gray-button-style);
    
    @apply h-fit flex items-center justify-center select-none cursor-pointer w-fit  text-white text-[12px] py-1 px-3 rounded-xl ease-in-out duration-300 border border-b-4 border-t-0 shadow-md
}

.button_gray_square:hover {
    transition: .1s linear;
    @apply border-green-500 bg-green-600 
}

.button_gray_square:active {
    transition: .1s linear;

    box-shadow:  4px 4px 6px 0 rgba(255,255,255,.3),
    -4px -4px 6px 0 rgba(116, 125, 136, .2), 
    inset -4px -4px 6px 0 rgba(255,255,255,.2),
    inset 4px 4px 6px 0 rgba(0, 0, 0, .2);

    @apply border-none
}

/* ============================================================= */
/* ============================================================= */

/* КНОПКА красная толстая */
.button_red {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0); 
    -webkit-tap-highlight-color: transparent;

    -webkit-transition: all .1s ease-in-out;
    -moz-transition: all .1s ease-in-out;
    -ms-transition: all .1s ease-in-out;
    -o-transition: all .1s ease-in-out;
    transition: all .1s ease-in-out;

    animation-name: dance_button;
    animation-duration: 4s;
    animation-iteration-count: infinite;

    background-color: var( --text-color-red-700);
    border-color: var( --text-color-red-500);
    
    @apply h-11 select-none cursor-pointer w-full  text-white py-2 px-4 rounded-full ease-in-out duration-300 border border-b-4 border-t-0 shadow-md
}

.button_red:hover {
    transition: .1s linear;
    @apply border-red-300 bg-red-600 
}

.button_red:active {
    transition: .1s linear;

    box-shadow:  4px 4px 6px 0 rgba(255,255,255,.3),
    -4px -4px 6px 0 rgba(116, 125, 136, .2), 
    inset -4px -4px 6px 0 rgba(255,255,255,.2),
    inset 4px 4px 6px 0 rgba(0, 0, 0, .2);

    @apply border-none
}

/* ============================================================= */
/* ============================================================= */

/* КНОПКА зелёная толстая */
.button_green {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0); 
    -webkit-tap-highlight-color: transparent;

    -webkit-transition: all .1s ease-in-out;
    -moz-transition: all .1s ease-in-out;
    -ms-transition: all .1s ease-in-out;
    -o-transition: all .1s ease-in-out;
    transition: all .1s ease-in-out;

    animation-name: dance_button;
    animation-duration: 4s;
    animation-iteration-count: infinite;

    /* background-color: var( --color-green-dark);
    border-color: var( --color-green-light); */
    
    @apply flex bg-green-500 border-green-400 items-center justify-center h-11 select-none cursor-pointer w-full  text-white font-bold py-2 px-4  rounded-full ease-in-out duration-300 border border-b-4 border-t-0 shadow-md
}

.button_green:hover {
    transition: .1s linear;
    @apply border-green-500 bg-green-600 
}

.button_green:active {
    transition: .1s linear;

    box-shadow:  4px 4px 6px 0 rgba(255,255,255,.3),
    -4px -4px 6px 0 rgba(116, 125, 136, .2), 
    inset -4px -4px 6px 0 rgba(255,255,255,.2),
    inset 4px 4px 6px 0 rgba(0, 0, 0, .2);

    @apply border-none
}

/* ============================================================= */
/* ============================================================= */

.button_in_navigation {
  background-color: var(--button-blue_bg_color);

}

.button_blue {
    /* -webkit-tap-highlight-color: rgba(255, 255, 255, 0);  */
    -webkit-tap-highlight-color: transparent;
    border-color: var(--second-blue_element);


    -webkit-transition: all .1s ease-in-out;
    -moz-transition: all .1s ease-in-out;
    -ms-transition: all .1s ease-in-out;
    -o-transition: all .1s ease-in-out;
    transition: all .1s ease-in-out;

    animation-name: dance_button;
    animation-duration: 4s;
    animation-iteration-count: infinite;

    background-color: var(--button-blue_bg_color);
    color: var(--constant-white);
    
    @apply h-11 select-none cursor-pointer w-full outline-none font-bold py-2 px-4 rounded ease-in-out duration-300 border-b-4 border-t-0   shadow-md
}

.button_blue:hover {
    transition: .1s linear;
    @apply border-blue-200 bg-blue-300 
}

.button_blue:active {
    transition: .1s linear;

    box-shadow:  4px 4px 6px 0 rgba(255,255,255,.3),
    -4px -4px 6px 0 rgba(116, 125, 136, .2), 
    inset -4px -4px 6px 0 rgba(255,255,255,.2),
    inset 4px 4px 6px 0 rgba(0, 0, 0, .2);

    @apply border-none
}

/* ============================================================= */
/* ============================================================= */
.button_gray_rounded {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0); 
  -webkit-tap-highlight-color: transparent;

  -webkit-transition: all .6s ease-in-out;
  -moz-transition: all .6s ease-in-out;
  -ms-transition: all .6s ease-in-out;
  -o-transition: all .6s ease-in-out;
  transition: all .6s ease-in-out;

  /* background: radial-gradient(farthest-side at top left, blue, #aaa); */

  /* text-shadow: 1px 0px 4px black; */
  /* background-image: linear-gradient(45deg, rgba(255,255,255,.0) 30%, rgba(255,255,255,.8), rgba(255,255,255,.0) 70%); */
  
  /* background-repeat: no-repeat;
  background-size: 200% 100%, auto, 100% 2px, 100% 2px, 100% 1px, 100% 1px;
  background-position: 200% 0, 0 0, 0 0, 0 100%, 0 4px, 0 calc(100% - 4px); */

  animation-name: dance_button;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  background-color: var(--bright-gray-button-style);
  
  @apply h-11 select-none cursor-pointer w-fit outline-none text-white font-bold text-[99%] py-2 px-4 rounded-full
}

/* ============================================================= */
/* ============================================================= */

.button_blue_rounded {

    /* -webkit-tap-highlight-color: rgba(255, 255, 255, 0);  */
    -webkit-tap-highlight-color: transparent;

    -webkit-transition: all .6s ease-in-out;
    -moz-transition: all .6s ease-in-out;
    -ms-transition: all .6s ease-in-out;
    -o-transition: all .6s ease-in-out;
    transition: all .6s ease-in-out;


    /* background: radial-gradient(farthest-side at top left, blue, #aaa); */

    /* text-shadow: 1px 0px 4px black; */
    /* background-image: linear-gradient(45deg, rgba(255,255,255,.0) 30%, rgba(255,255,255,.8), rgba(255,255,255,.0) 70%); */
    
    /* background-repeat: no-repeat;
    background-size: 200% 100%, auto, 100% 2px, 100% 2px, 100% 1px, 100% 1px;
    background-position: 200% 0, 0 0, 0 0, 0 100%, 0 4px, 0 calc(100% - 4px); */

    animation-name: dance_button;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    background-color: var(--button-blue_bg_color);
    color: var(--constant-white);
    border-color: var(--second-blue_element);

    @apply h-11 select-none cursor-pointer w-full outline-none  font-bold text-[99%] py-2 px-4 rounded-full border border-b-4  border-t-0   shadow-md
}

.button_blue_rounded:hover {
    transition: .5s linear;
    background-position: -200% 0, 0 0, 0 0, 0 100%, 0 4px, 0 calc(100% - 4px);
    @apply border-blue-200 bg-blue-300 
}

.button_blue_rounded:active {
    transition: .5s linear;

    box-shadow:  4px 4px 6px 0 rgba(255,255,255,.3),
    -4px -4px 6px 0 rgba(116, 125, 136, .2), 
    inset -4px -4px 6px 0 rgba(255,255,255,.2),
    inset 4px 4px 6px 0 rgba(0, 0, 0, .2);

    @apply border-none 
}


/* ============================================================= */
/* ============================================================= */

/* PINK BUTTON */

.custom-btn {
    width: 130px;
    height: 40px;
    color: #fff;
    border-radius: 5px;
    padding: 10px 25px;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
     box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
     7px 7px 20px 0px rgba(0,0,0,.1),
     4px 4px 5px 0px rgba(0,0,0,.1);
    outline: none;
  }
  .btn-11 {
    border: none;
    background: rgb(251,33,117);
      background: linear-gradient(0deg, rgba(251,33,117,1) 0%, rgba(234,76,137,1) 100%);
      color: #fff;
      overflow: hidden;
  }
  .btn-11:hover {
      text-decoration: none;
      color: #fff;
  }
  .btn-11:before {
      position: absolute;
      content: '';
      display: inline-block;
      top: -180px;
      left: 0;
      width: 30px;
      height: 100%;
      background-color: #fff;
      animation: shiny-btn1 3s ease-in-out infinite;
  }
  .btn-11:hover{
    opacity: .7;
  }
  .btn-11:active{
  box-shadow:  4px 4px 6px 0 rgba(255,255,255,.3),
              -4px -4px 6px 0 rgba(116, 125, 136, .2), 
    inset -4px -4px 6px 0 rgba(255,255,255,.2),
    inset 4px 4px 6px 0 rgba(0, 0, 0, .2);
  }
  
  @-webkit-keyframes shiny-btn1 {
      0% { -webkit-transform: scale(0) rotate(45deg); opacity: 0; }
      80% { -webkit-transform: scale(0) rotate(45deg); opacity: 0.5; }
      81% { -webkit-transform: scale(4) rotate(45deg); opacity: 1; }
      100% { -webkit-transform: scale(50) rotate(45deg); opacity: 0; }
  }
/* PINK BUTTON END */

/* ============================================================= */
/* ============================================================= */

.button_gley_like_blue {
    @apply w-full bg-gray-300 text-white font-bold py-2 px-4 rounded
}

/* КНОПКА синяя толстая */
.button_base {
    background-color: var(--color-blue-300);
    color: white;
    font: bold;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    border-radius: 0.25rem;  /*rounded*/
}

.button_base:hover {
    background-color: var(--color-blue-400);
}

/* ============================================================= */
/* .addon_to_green_button {
    width: 1%;
    white-space: nowrap;
} */

/* Зафиксировать состояние анимации на последнем кадре */
/* .addon_to_green_button:hover {
    animation-name: zoom_size_button;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards; 
} */
/* ============================================================= */


@keyframes zoom_size_button {
    0%   { width: 2%; height: 2%; }
    100%  { width: 100%; }
  }

@-webkit-keyframes zoom_size_button {
    0%   { -webkit-width: 2%; height: 2%; }
    100%  { -webkit-width: 100%; }
}

  .addon_to_green_button:not(:hover) {
    animation-name: zoom_size_button_out;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards; /* Зафиксировать состояние анимации на последнем кадре */
  }
  
  @keyframes zoom_size_button_out {
    0%   { width: 100%; }
    100% { width: 1%; height: 1%; }
  }

  @-webkit-keyframes zoom_size_button_out {
    0%   { -webkit-width: 100%;}
    100%  { -webkit-width: 1%;  height: 1%;  }
}


.button_grey_like_green {
    border-radius: 20px;
    padding: 0.32rem 1rem 0.375rem 1rem;
    color: white;
    background-color: var(--bright-gray-button-style);
    border-radius: 999px;
    /* padding-left: 4rem;
    padding-right: 4rem; */
    width: 100%;
    @apply h-11;
}

.button_grey_like_green:hover {
    animation: dontActiveButton 2s ease 0s 1 normal forwards;

}

/* КНОПКА синяя тонкая */
.button_blue_mini {
    background-color: var(--button-blue_bg_color);
    color: var(--text-inverted);
    border-color: var(--second-blue_element);

    border-radius: 9999px; /*rounded-full*/
    margin: 0.25rem; /* 4px */
    padding-left: calc(var(--rem_1)*3);
    padding-right: calc(var(--rem_1)*3);
    margin-left: var(--rem_1);
    width: fit-content; /* w-fit */
    font-size: 10px;
    cursor: pointer;
    /* @apply bg-blue-300 text-white rounded-full m-12 pl-12 pr-1 ml-4 w-fit text-[10px] cursor-pointer */
}

.button_blue_mini:hover {
    background-color: var(--color-blue-400);
}

/* КНОПКА зелёная тонкая */
.button_green_mini {
    background-color: var(--color-green-light);
    color:white;
    border-radius: 9999px; /*rounded-full*/
    margin: 0.25rem; /* 4px */
    padding-left: calc(var(--rem_1)*3);
    padding-right: calc(var(--rem_1)*3);
    margin-left: var(--rem_1);
    width: fit-content; /* w-fit */
    font-size: 10px;
    cursor: pointer;
}

.button_green_mini:hover {
    background-color: var(--color-green-dark);
}

/* Круглая градиентная кнопка серая */
.button_grey_gradient {
    display: block;
    border: none;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: 0 auto;
    background: radial-gradient(farthest-side at top left, #fff, #aaa);
    box-shadow: 5px 10px 20px rgba(0,0,0,0.3), -5px -10px 20px rgba(255,255,255,0.5);
}

/* ============================================================= */
/* ============================================================= */
/* RED BUTTON PILOT */
.switch {
    display: block;
    background-color: black;
    width: 40px;
    height: 50px;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2), 0 0 1px 2px black, inset 0 2px 2px -2px white, inset 0 0 2px 15px #47434c, inset 0 0 2px 22px black;
    border-radius: 5px;
    /* padding: 1px; */
    /* padding-top: 10px;
    padding-bottom: 10px; */
    perspective: 700px;
  }
  
  .switch input {
    display: none;
  }
  
  .switch input:checked + .button {
    transform: translateZ(20px) rotateX(25deg);
    box-shadow: 0 -10px 20px #ff1818;
  }
  
  .switch input:checked + .button .light {
    animation: flicker 0.2s infinite 0.3s;
  }
  
  .switch input:checked + .button .shine {
    opacity: 1;
  }
  
  .switch input:checked + .button .shadow {
    opacity: 0;
  }
  
  .switch .button {
    display: block;
    transition: all 0.3s cubic-bezier(1, 0, 1, 1);
    transform-origin: center center -20px;
    transform: translateZ(20px) rotateX(-25deg);
    transform-style: preserve-3d;
    background-color: #9b0621;
    height: var(--variable_for_red_button_pilot);
    position: relative;
    cursor: pointer;
    background: linear-gradient(#980000 0%, #6f0000 30%, #6f0000 70%, #980000 100%);
    background-repeat: no-repeat;
  }
  
  .switch .button::before {
    content: "";
    background: linear-gradient(rgba(255, 255, 255, 0.8) 10%, rgba(255, 255, 255, 0.3) 30%, #650000 75%, #320000) 50% 50%/97% 97%, #b10000;
    background-repeat: no-repeat;
    width: var(--variable_for_red_button_pilot);
    height: 50px;
    transform-origin: top;
    transform: rotateX(-80deg);
    position: absolute;
    top: 0;
  }
  
  .switch .button::after {
    content: "";
    background-image: linear-gradient(#650000, #320000);
    width: var(--variable_for_red_button_pilot);
    height: 50px;
    transform-origin: top;
    transform: translateY(50px) rotateX(-100deg);
    position: absolute;
    bottom: 0;
    /* box-shadow: 0 50px 8px 0px black, 0 80px 20px 0px rgba(0, 0, 0, 0.5); */
  }
  
  .switch .light {
    opacity: 0;
    animation: light-off 1s;
    position: absolute;
    width: var(--variable_for_red_button_pilot);
    height: 100%;
    background-image: radial-gradient(#ffc97e, #ff1818 40%, transparent 70%);
  }
  
  .switch .dots {
    position: absolute;
    width: var(--variable_for_red_button_pilot);
    height: 100%;
    background-image: radial-gradient(transparent 30%, rgba(101, 0, 0, 0.7) 70%);
    background-size: 10px 10px;
  }
  
  .switch .characters {
    position: absolute;
    width: var(--variable_for_red_button_pilot);
    height: 100%;
    background: linear-gradient(white, white) 50% 20%/5% 20%, radial-gradient(circle, transparent 50%, white 52%, white 70%, transparent 72%) 50% 80%/33% 25%;
    background-repeat: no-repeat;
  }
  
  .switch .shine {
    transition: all 0.3s cubic-bezier(1, 0, 1, 1);
    opacity: 0.3;
    position: absolute;
    width: var(--variable_for_red_button_pilot);
    height: 100%;
    background: linear-gradient(white, transparent 3%) 50% 50%/97% 97%, linear-gradient(rgba(255, 255, 255, 0.5), transparent 50%, transparent 80%, rgba(255, 255, 255, 0.5)) 50% 50%/97% 97%;
    background-repeat: no-repeat;
  }
  
  .switch .shadow {
    transition: all 0.3s cubic-bezier(1, 0, 1, 1);
    opacity: 1;
    position: absolute;
    width: var(--variable_for_red_button_pilot);
    height: 100%;
    background: linear-gradient(transparent 70%, rgba(0, 0, 0, 0.8));
    background-repeat: no-repeat;
  }
  
  @keyframes flicker {
    0% {
      opacity: 1;
    }
  
    80% {
      opacity: 0.8;
    }
  
    100% {
      opacity: 1;
    }
  }
  
  @keyframes light-off {
    0% {
      opacity: 1;
    }
  
    80% {
      opacity: 0;
    }
  }

/* ============================================================= */
/* ============================================================= */